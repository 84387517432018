.auth-form {
  max-width: 400px;  /* Increased from 450px to 600px */
  width: 100%;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-title {
  color: #255F85;
  text-align: center;
  font-size: 28px;
  font-weight: bold;  /* Add this line to make the text bold */
  margin-bottom: 20px;
  width: 100%;
}

.auth-form input {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 20px;
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.auth-form input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.auth-form button {
  width: 100%;
  padding: 12px;
  background-color: #255F85;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-form button:hover {
  background-color: #1E4A6A;
}

/* Add these new styles for the Google sign-in button */
.auth-form .google-sign-in {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-form .google-sign-in:hover {
  background-color: #f8f8f8;
}

.auth-form .google-sign-in svg {
  margin-right: 10px;
}

.auth-form p {
  margin-top: 25px;
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.auth-form a {
  color: #255F85;
  text-decoration: none;
  font-weight: 600;
}

.auth-form a:hover {
  text-decoration: underline;
}

.auth-form .error-message {
  color: #e74c3c;
  margin-bottom: 15px;
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.auth-form .success-message {
  color: #2ecc71;
  margin-bottom: 15px;
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.auth-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;  /* Changed from 800px to 600px */
  padding: 20px;
}

.auth-logo {
  width: 80px;  /* Reduced from 100px to 80px */
  margin-bottom: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.auth-logo:hover {
  opacity: 0.8;
}

.auth-form {
  /* Existing styles */
  max-width: 400px;  /* Increased from 450px to 600px */
  width: 100%;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: white;
  /* Remove margin-top if present */
}

.auth-form .input-group {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.auth-form .input-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #dadce0;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.auth-form .input-group::after {
  content: '*';
  color: red;
  position: absolute;
  top: 12px;
  right: 10px;
}

.google-sign-in {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-sign-in:hover {
  background-color: #f8f8f8;
}

.google-sign-in svg {
  margin-right: 10px;
}

/* Remove this style as we're not using an img tag anymore
.google-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
*/

.auth-separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.auth-separator::before,
.auth-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #dadce0;
}

.auth-separator span {
  padding: 0 10px;
  color: #757575;
  font-size: 14px;
  white-space: nowrap;
}

/* Add these new styles to ensure the lines are visible */
.auth-separator::before {
  margin-right: 10px;
}

.auth-separator::after {
  margin-left: 10px;
}

.forgot-password-link {
  text-align: right;
  margin-top: -15px;
  margin-bottom: 20px;
}

.forgot-password-link a {
  color: #255F85;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password-link a:hover {
  text-decoration: underline;
}

.auth-button {
  width: 100%;
  padding: 12px;
  background-color: #255F85;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: normal; /* Changed from 600 to normal */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-button:hover {
  background-color: #1E4A6A;
}

/* ... rest of the existing styles ... */