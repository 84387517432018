.legal-page {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding-top: 2rem; /* Adjusted padding since there's no navbar */
}

.legal-page h1 {
  color: #255F85;
  margin-bottom: 1.5rem;
  text-align: center;
}

.legal-page h2 {
  color: #255F85;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.legal-page p {
  margin-bottom: 1rem;
}

.legal-page .prose {
  max-width: 65ch;
  margin: 0 auto;
}

.legal-page hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-top: 2px solid #e5e7eb;
}

@media (max-width: 768px) {
  .legal-page {
    padding-top: 64px; /* Keep the same padding for mobile */
  }
}