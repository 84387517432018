.footer {
  background-color: var(--dark-gray);
  color: black; /* Changed from white to black */
  padding: 0.5rem 1rem; /* Adjusted padding to be more compact */
  margin-left: 1rem; /* Add left margin */
  margin-right: 1rem; /* Add right margin */
  margin-top: 0; /* Remove top margin */
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; /* Align items creatively */
  align-items: center;
  text-align: left;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1; /* Allow flex-grow */
}

.footer-logo {
  width: 60px; /* Smaller logo */
  margin-bottom: 0.25rem; /* Reduced bottom margin */
  margin-top: 0; /* Ensure no top margin */
}

.footer-site-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.25rem; /* Reduced bottom margin */
  color: black; /* Changed from blue to black */
}

.footer-address {
  font-size: 1rem;
  margin-bottom: 0.5rem; /* Reduced bottom margin */
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1; /* Allow flex-grow */
  margin-bottom: 1rem; /* Add margin-bottom to create space */
}

.footer-social-media {
  display: flex;
  gap: 0.5rem; /* Reduced gap */
  margin-bottom: 1rem; /* Add margin-bottom to create space */
}

.footer-social-media a {
  color: black; /* Change icon color to black */
  font-size: 1.2rem; /* Adjust size as needed */
  background-color: #e0e0e0; /* Light grey background */
  width: 2.5rem; /* Set width and height to create a circle */
  height: 2.5rem;
  border-radius: 50%; /* Make it circular */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.footer-social-media a:hover,
.footer-social-media a:active {
  background-color: #c0c0c0; /* Darker grey on hover/active */
}

.copyright {
  font-size: 0.9rem;
  color: #a0a0a0;
}

.footer-links {
  margin-bottom: 0.5rem;
}

.footer-links a {
  color: #555;
  text-decoration: none;
  margin-right: 1rem;
  font-size: 0.9rem;
}

.footer-links a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack elements on top of each other */
    align-items: flex-start; /* Align items to the left */
    margin-left: 0.5rem; /* Reduce left margin on mobile */
    margin-right: 0.5rem; /* Reduce right margin on mobile */
  }

  .footer-right {
    align-items: flex-start; /* Align items to the left */
    margin-top: 0.5rem; /* Reduced top margin */
  }
}

.footer-logo-link {
  display: inline-block;
  text-decoration: none;
}

.footer-logo-link:hover {
  opacity: 0.8;
}