.admin-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.admin-dashboard h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.admin-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.tab-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}

.admin-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-form {
  display: grid;
  gap: 15px;
  margin-bottom: 30px;
}

.admin-form input,
.admin-form select,
.admin-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.admin-form label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-list,
.voucher-list {
  display: grid;
  gap: 20px;
}

.product-item,
.voucher-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-group {
  display: flex;
  gap: 10px;
}

.primary-button,
.secondary-button,
.edit-button,
.delete-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: opacity 0.3s ease;
}

.primary-button {
  background-color: #007bff;
  color: white;
}

.secondary-button {
  background-color: #6c757d;
  color: white;
}

.edit-button {
  background-color: #ffc107;
  color: #212529;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.primary-button:hover,
.secondary-button:hover,
.edit-button:hover,
.delete-button:hover {
  opacity: 0.8;
}

.question-item {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

.option-input {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.option-input input[type="text"] {
  flex-grow: 1;
}

.option-input input[type="number"] {
  width: 60px;
}

@media (max-width: 768px) {
  .admin-tabs {
    flex-direction: column;
    align-items: center;
  }

  .tab-button {
    margin: 5px 0;
    width: 100%;
  }

  .product-item,
  .voucher-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .button-group {
    margin-top: 10px;
  }
}

.category-filter {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.category-filter label {
  font-weight: bold;
}

.category-filter select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #f0f0f0;
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
}

.pagination button:hover:not(.active) {
  background-color: #ddd;
}