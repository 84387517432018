.test-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.test-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.test-header-left {
  display: flex;
  align-items: center;
}

.logo {
  width: 60px;
  height: auto;
  margin-right: 20px; /* Add a small right margin */
  margin-top: 20px;
}

.test-info {
  display: flex;
  flex-direction: column;
  padding-left: 20px; /* Reduce left padding */
}

.test-title {
  color: #000000;
  font-size: 24px;
  margin: 0;
  font-weight: bold;
}

.test-taker {
  color: #255F85;
  font-size: 16px;
  margin: 5px 0 0;
  font-weight: bold;
}

.timer {
  font-size: 24px;
  font-weight: bold;
  color: white;
  background-color: #255F85;  
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  transition: background-color 0.3s ease;
}

.timer::before {
  content: "\f017";
  font-family: "Font Awesome 5 Free";
  margin-right: 8px;
  font-size: 20px;
  color: white;
}

.timer.warning {
  background-color: #C5283D;
}

.progress-bar {
  width: 100%;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 3px;
  margin-bottom: 20px;
}

.progress {
  height: 100%;
  background-color: #FFC857;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.test-content {
  display: flex;
  gap: 20px;
}

.question-container {
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.question-navigation {
  width: 250px;
  display: flex;
  flex-direction: column;
}

.question-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px; /* Increased gap slightly */
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px; /* Increased padding slightly */
}

.question-number {
  font-size: 16px;
  color: #888;
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  color: #000000;
}

.question-number.active {
  background-color: #F0F3BD;
  color: #000000;
  border-color: #F0F3BD;
}

.question-number.answered {
  background-color: #006400;
  color: white;
  border-color: #006400;
}

.question-text {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.options {
  display: grid;
  gap: 10px;
}

.option {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
  color: #000000;
}

.option:hover {
  background-color: #f0f0f0;
}

.option.selected {
  background-color: #F0F3BD;
  border-color: transparent;
}

.option-letter {
  min-width: 30px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFC857;
  color: #000000;
  border-radius: 50%;
  margin-right: 10px;
  font-weight: bold;
}

.option-text {
  flex-grow: 1;
  color: #000000;
  display: flex;
  align-items: center;
  min-height: 30px; /* Match the height of the option-letter */
}

.navigation {
  display: flex;
  gap: 10px;
}

.nav-button, .submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-button {
  background-color: #f0f0f0;
  color: #000000;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.nav-button:hover:not(:disabled) {
  background-color: #e0e0e0;
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.submit-button {
  width: 100%;
  margin-top: 20px;
  background-color: #C5283D;
  color: white;
}

.submit-button:hover {
  background-color: #a82235;
}

.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.confirmation-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.confirmation-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.confirmation-popup button:first-child {
  background-color: #000000;
  color: white;
}

.confirmation-popup button:last-child {
  background-color: #6c757d;
  color: white;
}

.question-label {
  font-size: 16px;
  color: #888;
  margin-bottom: 10px;
}

.question-number-button {
  width: 100%; /* Changed from fixed width */
  height: 36px; /* Increased height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  color: #000000;
  font-size: 14px; /* Increased font size */
  padding: 0; /* Remove padding */
}

.question-number-button.active {
  background-color: #F0F3BD;
  color: #000000;
  border-color: #F0F3BD;
}

.question-number-button.answered {
  background-color: #1A5E63;
  color: white;
  border-color: #1A5E63;
}

.icon-white {
  color: white;
  background-color: #000000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  font-size: 0.7em;
}

.option-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.clear-button {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s ease;
}

.clear-button:hover {
  background-color: #e0e0e0;
}

.question-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.clear-button-container {
  width: 80px;
}

.navigation {
  display: flex;
  gap: 10px;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.question-label {
  font-size: 16px;
  color: #888;
  margin: 0;
}

.question-type {
  font-size: 14px;
  color: #1A5E63;
  font-weight: bold;
  margin: 0;
}

@media (max-width: 768px) {
  .test-container {
    padding: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .logo {
    max-width: 70px;
    margin-bottom: 15px; /* Reduced from 30px to 15px */
    margin-top: 20px;
    margin-right: 0; /* Reset margin-right for mobile */
  }

  .test-content {
    flex-direction: column;
    flex-grow: 1;
  }

  .question-navigation {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .question-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Keep 5 columns on mobile */
    gap: 8px; /* Increased gap slightly */
    margin-bottom: 20px;
    max-height: 220px; /* Increased max-height for mobile */
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px; /* Increased padding slightly */
  }

  .question-number-button {
    width: 100%;
    height: 32px; /* Increased height for mobile */
    font-size: 12px; /* Increased font size for mobile */
    padding: 0; /* Remove padding */
  }

  .submit-button-container {
    margin-top: auto;
    padding: 0 20px;
  }

  .submit-button {
    width: 100%;
    max-width: 300px;
    padding: 15px 0;
    font-size: 18px;
    margin: 0 auto;
    display: block;
  }

  .test-header {
    flex-direction: column;
    align-items: center;
  }

  .test-header-left {
    flex-direction: column;
    align-items: center;
  }

  .logo {
    margin-right: 10px; /* Adjust for mobile */
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .test-info {
    text-align: center;
    padding-left: 0; /* Remove padding for mobile */
  }

  .test-title {
    font-size: 20px;
    text-align: center;
  }

  .test-taker {
    font-size: 14px;
    text-align: center;
  }

  .timer {
    margin-top: 10px;
  }

  .options {
    grid-template-columns: 1fr;
  }

  .option {
    padding: 8px;
  }

  .option-letter {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }

  .option-text {
    font-size: 14px;
  }

  .nav-button {
    padding: 8px 16px;
  }

  .confirmation-content {
    width: 90%;
    max-width: 300px;
  }

  .confirmation-buttons {
    flex-direction: column;
  }

  .confirmation-buttons button {
    margin: 5px 0;
  }
}

.question-image {
  max-width: 100%;
  max-height: 300px; /* Add this line */
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  object-fit: contain; /* Add this line */
}

@media (max-width: 768px) {
  .question-image {
    max-height: 200px; /* Add this line for mobile */
  }
}