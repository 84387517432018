@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --primary-color: #000000;
  --secondary-color: #2ecc71;
  --background-color: #ffffff;
  --text-color: #000000;
  --card-background: #ffffff;
  --font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #ffffff;
}

body {
  font-family: var(--font-family);
  background-color: #ffffff;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.dashboard-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-color: #ffffff;
}

.sidebar {
  width: 250px;
  background-color: #ffffff;
  color: var(--text-color);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.sidebar-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.sidebar-top {
  flex-grow: 1;
}

.sidebar-bottom {
  margin-top: auto;
}

.sidebar h2 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;
}

.sidebar a {
  display: flex;
  align-items: center;
  color: var(--text-color);
  text-decoration: none;
  padding: 12px 10px 12px 30px;
  transition: background-color 0.3s ease, border-radius 0.3s ease;
  border-radius: 0;
  margin-bottom: 5px;
  position: relative;
}

.sidebar a i {
  margin-right: 15px;
  width: 20px;
  text-align: center;
}

.sidebar a:hover, .sidebar a.active {
  background-color: #F0F3BD;
  color: #000000;
  border-radius: 10px;
}

.main-content {
  flex: 1;
  padding: 20px;
  margin-left: 250px;
  background-color: #ffffff;
  min-height: 100vh;
  width: calc(100% - 250px);
  box-sizing: border-box;
  overflow-x: hidden;
}

.dashboard-content {
  max-width: 100%;
  overflow-x: hidden;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.category-section {
  width: 100%;
  overflow: hidden;
  padding: 0;
}

.product-grid {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 0 20px 20px;
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f0f0f0;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.product-grid::-webkit-scrollbar {
  height: 4px;
}

.product-grid::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.product-grid::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 2px;
}

.product-grid::-webkit-scrollbar-thumb:hover {
  background-color: #bbbbbb;
}

.product-card {
  flex: 0 0 300px;
  min-width: 300px;
  max-width: 300px;
  scroll-snap-align: start;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  height: 210px;
  transition: box-shadow 0.3s ease;
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.new-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(-45deg, #ff4757, #ff6b81, #ff4757, #ff6b81);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.product-card:hover {
  box-shadow: 0 -8px 10px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.product-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.category-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}

.product-card h3 {
  margin-top: 25px;
  margin-bottom: 8px;
  font-size: 1.3em;
  line-height: 1.3;
  max-height: 2.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  color: #000000;
}

.product-card p {
  margin: 4px 0;
  font-size: 0.8em;
  line-height: 1.3;
}

.product-card p i {
  margin-right: 5px;
  font-size: 1.1em;
}

.icon-white {
  color: white;
  background-color: #000000;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  font-size: 0.8em;
}

.product-card .price {
  margin-top: 3px;
  font-size: 0.85em;
  font-weight: 500;
}

.product-card button {
  padding: 8px 10px;
  font-size: 0.8em;
  margin-top: 8px;
  background-color: #255F85;
  color: #ffffff;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--font-family);
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.product-card button::after {
  content: ' \2192';
  margin-left: 3px;
}

.product-card button:hover {
  background-color: #1E4A6A;
}

.promo-banner-container {
  width: 100%;
  margin-bottom: 40px; /* Increased margin-bottom */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.promo-banner-image {
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    width: 100%;
    padding: 60px 10px 0;
  }

  .category-section {
    padding: 0 10px;
  }

  .product-grid {
    padding: 0 0 20px 10px;
    gap: 10px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .product-card {
    flex: 0 0 300px;
    min-width: 300px;
    max-width: 300px;
    height: 210px;
    margin-right: 10px;
    padding: 12px;
  }

  .product-card:last-child {
    margin-right: 10px;
  }

  .product-card h3 {
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 6px;
  }

  .product-card p {
    font-size: 0.75em;
    margin: 3px 0;
  }

  .product-card button {
    padding: 8px 10px;
    font-size: 0.75em;
    margin-top: auto;
  }

  .category-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.6em;
    font-weight: bold;
  }

  .new-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.6em;
    font-weight: bold;
    background-color: #ff4757;
  }
}

.category-section {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.category-section:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.category-title {
  font-size: 1.5em;
  color: #000000;
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-weight: 600;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #666;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 1010; /* Ensure it's always on top */
  padding: 0;
}

.close-modal:hover,
.close-modal:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.close-modal::before {
  content: "\00d7"; /* Unicode for '×' */
  line-height: 1;
}

/* Remove any existing ::after pseudo-element */
.close-modal::after {
  content: none;
}

.modal-icon {
  font-size: 3em;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.modal-message {
  font-size: 1em;
  margin-bottom: 20px;
  color: #666;
}

.modal-action-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.modal-action-button:hover {
  opacity: 0.9;
}

/* Styles for the product details modal */
.larger-modal {
  max-width: 500px;
}

.modal-product-name {
  font-size: 1.5em; /* Reduced from 1.8em */
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.product-details {
  margin-bottom: 20px;
  background-color: #f5f5f5; /* Light grey background */
  border-radius: 8px;
  padding: 15px;
}

.detail-box {
  text-align: left;
}

.detail-box p {
  margin: 8px 0;
}

.price {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #255F85;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --primary-color: #000000;
  --secondary-color: #2ecc71;
  --background-color: #ffffff;
  --text-color: #000000;
  --card-background: #ffffff;
  --font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #ffffff;
}

body {
  font-family: var(--font-family);
  background-color: #ffffff;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.dashboard-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-color: #ffffff;
}

.sidebar {
  width: 250px;
  background-color: #ffffff;
  color: var(--text-color);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.sidebar-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.sidebar-top {
  flex-grow: 1;
}

.sidebar-bottom {
  margin-top: auto;
}

.sidebar h2 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;
}

.sidebar a {
  display: flex;
  align-items: center;
  color: var(--text-color);
  text-decoration: none;
  padding: 12px 10px 12px 30px;
  transition: background-color 0.3s ease, border-radius 0.3s ease;
  border-radius: 0;
  margin-bottom: 5px;
  position: relative;
}

.sidebar a i {
  margin-right: 15px;
  width: 20px;
  text-align: center;
}

.sidebar a:hover, .sidebar a.active {
  background-color: #F0F3BD;
  color: #000000;
  border-radius: 10px;
}

.main-content {
  flex: 1;
  padding: 20px;
  margin-left: 250px;
  background-color: #ffffff;
  min-height: 100vh;
  width: calc(100% - 250px);
  box-sizing: border-box;
  overflow-x: hidden;
}

.dashboard-content {
  max-width: 100%;
  overflow-x: hidden;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.category-section {
  width: 100%;
  overflow: hidden;
  padding: 0;
}

.product-grid {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 0 20px 20px;
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f0f0f0;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.product-grid::-webkit-scrollbar {
  height: 4px;
}

.product-grid::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.product-grid::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 2px;
}

.product-grid::-webkit-scrollbar-thumb:hover {
  background-color: #bbbbbb;
}

.product-card {
  flex: 0 0 300px;
  min-width: 300px;
  max-width: 300px;
  scroll-snap-align: start;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  height: 210px;
  transition: box-shadow 0.3s ease;
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.new-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(-45deg, #ff4757, #ff6b81, #ff4757, #ff6b81);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.product-card:hover {
  box-shadow: 0 -8px 10px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.product-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.category-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}

.product-card h3 {
  margin-top: 25px;
  margin-bottom: 8px;
  font-size: 1.3em;
  line-height: 1.3;
  max-height: 2.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  color: #000000;
}

.product-card p {
  margin: 4px 0;
  font-size: 0.8em;
  line-height: 1.3;
}

.product-card p i {
  margin-right: 5px;
  font-size: 1.1em;
}

.icon-white {
  color: white;
  background-color: #000000;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  font-size: 0.8em;
}

.product-card .price {
  margin-top: 3px;
  font-size: 0.85em;
  font-weight: 500;
}

.product-card button {
  padding: 8px 10px;
  font-size: 0.8em;
  margin-top: 8px;
  background-color: #255F85;
  color: #ffffff;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--font-family);
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.product-card button::after {
  content: ' \2192';
  margin-left: 3px;
}

.product-card button:hover {
  background-color: #1E4A6A;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    width: 100%;
    padding: 60px 10px 0;
  }

  .category-section {
    padding: 0 10px;
  }

  .product-grid {
    padding: 0 0 20px 10px;
    gap: 10px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .product-card {
    flex: 0 0 300px;
    min-width: 300px;
    max-width: 300px;
    height: 210px;
    margin-right: 10px;
    padding: 12px;
  }

  .product-card:last-child {
    margin-right: 10px;
  }

  .product-card h3 {
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 6px;
  }

  .product-card p {
    font-size: 0.75em;
    margin: 3px 0;
  }

  .product-card button {
    padding: 8px 10px;
    font-size: 0.75em;
    margin-top: auto;
  }

  .category-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.6em;
    font-weight: bold;
  }

  .new-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.6em;
    font-weight: bold;
    background-color: #ff4757;
  }
}

.category-section {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.category-section:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.category-title {
  font-size: 1.5em;
  color: #000000;
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-weight: 600;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #666;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 1010; /* Ensure it's always on top */
  padding: 0;
}

.close-modal:hover,
.close-modal:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.close-modal::before {
  content: "\00d7"; /* Unicode for '×' */
  line-height: 1;
}

/* Remove any existing ::after pseudo-element */
.close-modal::after {
  content: none;
}

.modal-icon {
  font-size: 3em;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.modal-message {
  font-size: 1em;
  margin-bottom: 20px;
  color: #666;
}

.modal-action-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.modal-action-button:hover {
  opacity: 0.9;
}

/* Styles for the product details modal */
.larger-modal {
  max-width: 500px;
}

.modal-product-name {
  font-size: 1.5em; /* Reduced from 1.8em */
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.product-details {
  margin-bottom: 20px;
  background-color: #f5f5f5; /* Light grey background */
  border-radius: 8px;
  padding: 15px;
}

.detail-box {
  text-align: left;
}

.detail-box p {
  margin: 8px 0;
}

.price {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #255F85;
}

.small-button.full-width {
  width: 100%;
  padding: 12px;
  font-size: 1em;
}

.menu-toggle, .close-sidebar {
  display: none;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
    z-index: 1000;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .menu-toggle, .close-sidebar {
    display: block;
  }
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --primary-color: #000000;
  --secondary-color: #2ecc71;
  --background-color: #ffffff;
  --text-color: #000000;
  --card-background: #ffffff;
  --font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #ffffff;
}

body {
  font-family: var(--font-family);
  background-color: #ffffff;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.dashboard-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-color: #ffffff;
}

.sidebar {
  width: 250px;
  background-color: #ffffff;
  color: var(--text-color);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.sidebar-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.sidebar-top {
  flex-grow: 1;
}

.sidebar-bottom {
  margin-top: auto;
}

.sidebar h2 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;
}

.sidebar a {
  display: flex;
  align-items: center;
  color: var(--text-color);
  text-decoration: none;
  padding: 12px 10px 12px 30px;
  transition: background-color 0.3s ease, border-radius 0.3s ease;
  border-radius: 0;
  margin-bottom: 5px;
  position: relative;
}

.sidebar a i {
  margin-right: 15px;
  width: 20px;
  text-align: center;
}

.sidebar a:hover, .sidebar a.active {
  background-color: #F0F3BD;
  color: #000000;
  border-radius: 10px;
}

.main-content {
  flex: 1;
  padding: 20px;
  margin-left: 250px;
  background-color: #ffffff;
  min-height: 100vh;
  width: calc(100% - 250px);
  box-sizing: border-box;
  overflow-x: hidden;
}

.dashboard-content {
  max-width: 100%;
  overflow-x: hidden;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.category-section {
  width: 100%;
  overflow: hidden;
  padding: 0;
}

.product-grid {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 0 20px 20px;
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f0f0f0;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.product-grid::-webkit-scrollbar {
  height: 4px;
}

.product-grid::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.product-grid::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 2px;
}

.product-grid::-webkit-scrollbar-thumb:hover {
  background-color: #bbbbbb;
}

.product-card {
  flex: 0 0 300px;
  min-width: 300px;
  max-width: 300px;
  scroll-snap-align: start;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  height: 210px;
  transition: box-shadow 0.3s ease;
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.new-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(-45deg, #ff4757, #ff6b81, #ff4757, #ff6b81);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.product-card:hover {
  box-shadow: 0 -8px 10px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.product-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.category-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}

.product-card h3 {
  margin-top: 25px;
  margin-bottom: 8px;
  font-size: 1.3em;
  line-height: 1.3;
  max-height: 2.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  color: #000000;
}

.product-card p {
  margin: 4px 0;
  font-size: 0.8em;
  line-height: 1.3;
}

.product-card p i {
  margin-right: 5px;
  font-size: 1.1em;
}

.icon-white {
  color: white;
  background-color: #000000;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  font-size: 0.8em;
}

.product-card .price {
  margin-top: 3px;
  font-size: 0.85em;
  font-weight: 500;
}

.product-card button {
  padding: 8px 10px;
  font-size: 0.8em;
  margin-top: 8px;
  background-color: #255F85;
  color: #ffffff;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--font-family);
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.product-card button::after {
  content: ' \2192';
  margin-left: 3px;
}

.product-card button:hover {
  background-color: #1E4A6A;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    width: 100%;
    padding: 60px 10px 0;
  }

  .category-section {
    padding: 0 10px;
  }

  .product-grid {
    padding: 0 0 20px 10px;
    gap: 10px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .product-card {
    flex: 0 0 300px;
    min-width: 300px;
    max-width: 300px;
    height: 210px;
    margin-right: 10px;
    padding: 12px;
  }

  .product-card:last-child {
    margin-right: 10px;
  }

  .product-card h3 {
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 6px;
  }

  .product-card p {
    font-size: 0.75em;
    margin: 3px 0;
  }

  .product-card button {
    padding: 8px 10px;
    font-size: 0.75em;
    margin-top: auto;
  }

  .category-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.6em;
    font-weight: bold;
  }

  .new-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.6em;
    font-weight: bold;
    background-color: #ff4757;
  }
}

.category-section {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.category-section:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.category-title {
  font-size: 1.5em;
  color: #000000;
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-weight: 600;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #666;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 1010; /* Ensure it's always on top */
  padding: 0;
}

.close-modal:hover,
.close-modal:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.close-modal::before {
  content: "\00d7"; /* Unicode for '×' */
  line-height: 1;
}

/* Remove any existing ::after pseudo-element */
.close-modal::after {
  content: none;
}

.modal-icon {
  font-size: 3em;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.modal-message {
  font-size: 1em;
  margin-bottom: 20px;
  color: #666;
}

.modal-action-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.modal-action-button:hover {
  opacity: 0.9;
}

/* Styles for the product details modal */
.larger-modal {
  max-width: 500px;
}

.modal-product-name {
  font-size: 1.5em; /* Reduced from 1.8em */
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.product-details {
  margin-bottom: 20px;
  background-color: #f5f5f5; /* Light grey background */
  border-radius: 8px;
  padding: 15px;
}

.detail-box {
  text-align: left;
}

.detail-box p {
  margin: 8px 0;
}

.price {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #255F85;
}

.small-button.full-width {
  width: 100%;
  padding: 12px;
  font-size: 1em;
}

.menu-toggle, .close-sidebar {
  display: none;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
    z-index: 1000;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .menu-toggle, .close-sidebar {
    display: block;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .menu-toggle {
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 1002;
    color: #000000;
    background-color: #F0F3BD;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }

  .menu-toggle:hover,
  .menu-toggle:focus {
    background-color: #E6E9B3;
  }

  .close-sidebar {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
  }

  .main-content {
    padding-top: 50px;
  }

  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-input {
    width: 100%;
    margin-top: 10px;
  }

  .product-grid {
    padding-bottom: 10px;
  }

  .product-card {
    width: 300px;
    min-width: 300px;
    height: 210px;
  }

  .product-grid {
    width: calc(200px * 2 + 45px);
  }
}

.category-section {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.category-section:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.category-title {
  font-size: 1.5em;
  color: #000000;
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-weight: 600;
}

footer, .footer {
  background-color: #ffffff;
  width: 100%;
}

.user-info {
  position: absolute;
  bottom: 60px;
  left: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #006400;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.user-avatar span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 1;
}

.user-details {
  flex-grow: 1;
  overflow: hidden;
}

.user-name {
  font-weight: bold;
  color: #333;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-email {
  font-size: 0.8em;
  color: #666;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sign-out {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  padding: 8px;
  background-color: #C5283D;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9em;
}

.sign-out:hover {
  background-color: #A11F33;
}

.cart-badge {
  background-color: #FFC857;
  color: black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 0.8em;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  line-height: 1;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 80%;
  width: 300px;
}

.close-popup {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #FFC857;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.close-popup:hover {
  background-color: rgba(255, 200, 87, 0.1);
}

.close-popup::before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 14px;
}

.inline-icon {
  display: flex;
  align-items: center;
}

.inline-icon svg {
  margin-right: 8px;
}

.checkmark-icon, .time-icon, .totalquestion-icon {
  border-radius: 50%;
}

.modal-product-name {
  color: black;
  font-weight: bold;
}

.dashboard-title {
  color: #000000;
  text-align: left;
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}

.dashboard-title::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e6e6e6;
  margin: 10px 0 20px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.sidebar-logo {
  width: 60px;
  height: 60px;
}

.sidebar-title {
  display: none;
}

.sidebar-separator {
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
}

.sidebar-top {
  display: flex;
  flex-direction: column;
}