* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.container-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.home {
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
}

.navbar-content,
.section-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .navbar-content,
  .section-content {
    padding: 0 2rem;
  }
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  width: 100%;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.nav-links a {
  margin-left: 1rem;
  text-decoration: none;
  color: #333;
}

.nav-links a.active {
  color: #FFC857;
}

.cta-button {
  padding: 0.5rem 1rem;
  background-color: #FFC857;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #E6B54E;
}

section {
  width: 100%;
  padding: 4rem 0;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

h1, h2 {
  margin-bottom: 1rem;
}

.hero-section {
  text-align: center;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.service-cards, .testimonial-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.service-card, .testimonial-card {
  flex-basis: calc(33.333% - 1rem);
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.faq-item {
  margin-bottom: 1rem;
}

.faq-item h3 {
  margin-bottom: 0.5rem;
}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-links {
    margin-top: 1rem;
  }

  .nav-links a {
    display: block;
    margin: 0.5rem 0;
  }

  .service-card, .testimonial-card {
    flex-basis: 100%;
    align: center;
  }

  .container-wrapper {
    padding: 0 15px;
  }
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  margin: 0;
  padding: 0;
}

.category-section {
  width: 100%;
  overflow: visible;
  padding: 0;
  margin-bottom: 30px;
}

.category-title {
  font-size: 1.5em;
  color: #000000;
  margin-bottom: 1.25rem;
  padding-bottom: 5px;
  font-weight: 600;
}

.product-grid-container {
  width: 100%;
  overflow-x: hidden;
  padding: 0;
}

.product-grid {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px 0;
  margin: 0;
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f0f0f0;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
}

.product-grid::-webkit-scrollbar {
  height: 4px;
}

.product-grid::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.product-grid::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 2px;
}

.product-grid::-webkit-scrollbar-thumb:hover {
  background-color: #bbbbbb;
}

.product-card {
  flex: 0 0 280px;
  min-width: 280px;
  max-width: 280px;
  height: 210px;
  scroll-snap-align: start;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.new-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(-45deg, #ff4757, #ff6b81, #ff4757, #ff6b81);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.product-card:hover {
  box-shadow: 0 -8px 10px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.category-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}

.product-card-content h3 {
  margin-top: 16px;
  margin-bottom: 4px;
  font-size: 0.9em;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  color: #000000;
}

.product-card-content p {
  margin: 2px 0;
  font-size: 0.65em;
  line-height: 1.2;
}

.product-card-button {
  padding: 4px 6px;
  font-size: 0.65em;
  margin-top: 4px;
  background-color: #255F85;
  color: #ffffff;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--font-family);
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.product-card-button::after {
  content: ' \2192';
  margin-left: 3px;
}

.product-card-button:hover {
  background-color: #1E4A6A;
}

@media (max-width: 1200px) {
  .product-grid {
    width: calc(200px * 3 + 24px);
  }
}

@media (max-width: 992px) {
  .product-grid {
    width: calc(200px * 2 + 12px);
  }
}

@media (max-width: 768px) {
  .product-grid-container {
    padding: 0 15px;
  }

  .product-grid {
    gap: 15px;
    padding: 15px 0;
    margin: 0 -15px;
  }

  .product-card {
    flex: 0 0 260px;
    min-width: 260px;
    max-width: 260px;
    height: 200px;
  }

  .product-grid::before {
    content: '';
    flex: 0 0 15px;
  }

  .product-grid::after {
    content: '';
    flex: 0 0 15px;
  }
}

.products-section {
  width: 100%;
  overflow: hidden;
}

.products-section > div {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

.products-section h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.category-section {
  width: 100%;
}

.product-grid-container {
  width: 100%;
  overflow-x: hidden;
}

.product-grid {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 20px;
  scroll-snap-type: x mandatory;
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f0f0f0;
}

.product-grid::-webkit-scrollbar {
  height: 4px;
}

.product-grid::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.product-grid::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 2px;
}

.product-card {
  flex: 0 0 300px;
  min-width: 300px;
  max-width: 300px;
  height: 210px;
  scroll-snap-align: start;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  transition: box-shadow 0.3s ease;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover {
  box-shadow: 0 -8px 10px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.category-tag {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.7em;
  color: white;
}

.product-card-content h3 {
  margin-top: 25px;
  margin-bottom: 8px;
  font-size: 1.1em;
  line-height: 1.3;
  max-height: 2.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  color: #000000;
}

.product-card-content p {
  margin: 4px 0;
  font-size: 0.8em;
  line-height: 1.3;
}

.product-card-button {
  padding: 8px 10px;
  font-size: 0.8em;
  margin-top: 8px;
  background-color: #255F85;
  color: #ffffff;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--font-family);
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.product-card-button::after {
  content: ' \2192';
  margin-left: 3px;
}

.product-card-button:hover {
  background-color: #1E4A6A;
}

@media (max-width: 768px) {
  .product-card {
    flex: 0 0 300px;
    min-width: 300px;
    max-width: 300px;
    height: 210px;
  }

  .container-wrapper {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.product-card {
  flex: 0 0 300px;
  min-width: 300px;
  max-width: 300px;
  height: 210px;
}

@media (max-width: 768px) {
  .product-card {
    flex: 0 0 300px;
    min-width: 300px;
    max-width: 300px;
    height: 210px;
  }
}

.product-grid-container {
  width: 100%;
  overflow-x: hidden;
  padding: 0;
}

.product-grid {
  display: flex;
  overflow-x: auto;
  gap: 12px;
  padding: 0 0 20px;
  margin: 0;
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f0f0f0;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
}

.product-card {
  flex: 0 0 280px;
  min-width: 280px;
  max-width: 280px;
  height: 210px;
  scroll-snap-align: start;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .section-content {
    padding: 0 15px;
  }

  .product-grid-container {
    margin: 0 -15px;
  }

  .product-grid {
    padding: 0 15px 20px;
  }

  .product-card {
    flex: 0 0 260px;
    min-width: 260px;
    max-width: 260px;
    height: 200px;
  }
}

.section-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  overflow-x: hidden;
  position: relative;
  overflow: visible;
}

.section-title-container {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  width: 100vw;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .section-content {
    padding: 0 15px;
  }

  .section-title {
    padding: 0 15px;
  }
}

.promotion-banner {
  background-color: #FFC857;
  color: black;
  text-align: center;
  padding: 0.5rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002; /* Increased z-index to be above the navbar */
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotion-banner p {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.2;
}

nav.fixed {
  position: fixed;
  top: 32px; /* Height of the promotion banner */
  left: 0;
  right: 0;
  z-index: 1001;
}

body {
  padding-top: 0;
}

main {
  padding-top: 128px; /* Adjust this value based on your navbar height (64px) + promotion banner height (32px) + extra space (32px) */
}

@media (max-width: 768px) {
  .promotion-banner {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }

  nav.fixed {
    top: 24px; /* Adjusted height of the promotion banner for mobile */
  }

  main {
    padding-top: 120px; /* Adjust for mobile: navbar (64px) + promotion (24px) + extra space (32px) */
  }

  .navbar-content {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .promotion-banner {
    padding: 0.375rem 0.75rem;
    font-size: 0.8125rem;
  }

  nav.fixed {
    top: 28px; /* Adjusted height of the promotion banner for tablet */
  }

  main {
    padding-top: 124px; /* Adjust for tablet: navbar (64px) + promotion (28px) + extra space (32px) */
  }

  .navbar-content {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

.product-card-button.disabled {
  background: linear-gradient(-45deg, #cccccc, #e0e0e0, #d4d4d4, #cccccc);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;
  color: #666666;
  cursor: not-allowed;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-text-container {
  display: inline-flex;
  align-items: baseline;
}

.animated-text {
  display: inline-block;
  min-width: 120px; /* Adjust based on the longest word */
  color: #255F85; /* Dark blue color */
  position: relative;
  overflow: visible;
  margin-left: 0.25em; /* Add a small margin to the left */
}

.animated-text-content {
  display: inline-block;
  position: relative; /* Change from absolute to relative */
  animation: slideUp 0.5s ease-in-out;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Ensure the animated text matches the surrounding text */
.animated-text, .animated-text-content {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

@media (max-width: 768px) {
  .animated-text {
    margin-left: 0.125em; /* Reduce the left margin on mobile */
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #255F85;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

