.purchased-products {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.purchased-title {
  color: #000000;
  text-align: left;
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 600;
}

.purchased-title::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e6e6e6;
  margin: 10px 0 20px;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.category-section {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin-bottom: 20px;
}

.category-title {
  font-size: 1.3em;
  color: #000000;
  margin-bottom: 15px;
  font-weight: 600;
}

.product-grid {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 0 20px 20px;
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f0f0f0;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.product-grid::-webkit-scrollbar {
  height: 4px;
}

.product-grid::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.product-grid::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 2px;
}

.product-grid::-webkit-scrollbar-thumb:hover {
  background-color: #bbbbbb;
}

.product-card {
  flex: 0 0 300px;
  min-width: 300px;
  max-width: 300px;
  scroll-snap-align: start;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  height: 210px;
  transition: box-shadow 0.3s ease;
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover {
  box-shadow: 0 -8px 10px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.product-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.category-tag {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.7em;
  color: white;
}

.product-card h3 {
  margin-top: 25px;
  margin-bottom: 8px;
  font-size: 1.3em;
  line-height: 1.3;
  max-height: 2.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  color: #000000;
}

.product-card p {
  margin: 4px 0;
  font-size: 0.8em;
  line-height: 1.3;
}

.purchased-products .product-card button {
  padding: 8px 10px;
  font-size: 0.8em;
  margin-top: 8px;
  background-color: #255F85 !important; /* Use !important to override */
  color: #FFFFFF !important; /* Use !important to override */
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--font-family);
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.purchased-products .product-card button:hover {
  background-color: #1E4B6A !important; /* Use !important to override */
}

.product-card button::after {
  content: ' \2192';
  margin-left: 3px;
}

.product-card button:hover {
  background-color: #1E4B6A;  /* Darker shade for hover state */
}

@media (max-width: 768px) {
  .purchased-products {
    padding: 20px 10px;
  }

  .category-section {
    padding: 0 10px;
  }

  .product-grid {
    padding: 0 0 20px 10px;
    gap: 10px;
  }

  .product-card {
    flex: 0 0 300px;
    min-width: 300px;
    max-width: 300px;
    height: 210px;
    margin-right: 10px;
    padding: 12px;
  }

  .product-card:last-child {
    margin-right: 10px;
  }

  .product-card h3 {
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 6px;
  }

  .product-card p {
    font-size: 0.75em;
    margin: 3px 0;
  }

  .product-card button {
    padding: 8px 10px;
    font-size: 0.75em;
    margin-top: auto;
  }

  .category-tag {
    font-size: 0.65em;
    padding: 2px 4px;
  }
}

.empty-purchased {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
}

.empty-purchased-message {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.empty-purchased-icon {
  font-size: 5em;
  margin-bottom: 20px;
  color: #ccc;
}

.empty-purchased-icon.animated {
  animation: boxBounce 2s ease-in-out infinite;
}

@keyframes boxBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.back-to-dashboard {
  padding: 10px 20px;
  background-color: #FFC857;
  color: #000000;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-to-dashboard:hover {
  background-color: #E6B54E;
}

.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
}

.confirmation-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.confirmation-buttons .confirm-yes {
  background-color: #255F85 !important;
  color: white !important;
}

.confirmation-buttons .confirm-yes:hover {
  background-color: #1E4B6A !important;
}

.confirm-no {
  background-color: #f44336;
  color: white;
}

.confirm-no:hover {
  background-color: #d32f2f;
}

.view-results-button {
  background-color: #FFC857 !important;
  color: #000000 !important;
  padding: 8px 10px;
  font-size: 0.8em;
  margin-top: 8px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  font-family: var(--font-family);
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.view-results-button:hover {
  opacity: 0.8;
  background-color: #FFC857 !important;
  color: #000000 !important;
}

.product-card .view-results-button {
  background-color: #FFC857 !important;
  color: #000000 !important;
}

.product-card .view-results-button:hover {
  background-color: #FFC857 !important;
  color: #000000 !important;
  opacity: 0.8;
}

.purchased-products .product-card .view-results-button {
  background-color: #FFC857 !important;
  color: #000000 !important;
  padding: 8px 10px;
  font-size: 0.8em;
  margin-top: 8px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--font-family);
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.purchased-products .product-card .view-results-button:hover {
  background-color: #E6B54E !important; /* Darker shade of yellow */
  color: #000000 !important;
}

/* Remove or comment out these conflicting styles */
/*
.product-card .view-results-button {
  background-color: #FFC857 !important;
  color: #000000 !important;
}

.product-card .view-results-button:hover {
  background-color: #FFC857 !important;
  color: #000000 !important;
  opacity: 0.8;
}
*/