.test-result {
  max-width: 600px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.test-result .logo {
  max-width: 100px;
  margin: 0 auto 20px;
  display: block;
}

.result-title {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bold;
}

.product-name {
  color: #555;
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: bold;
}

.score-breakdown {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
}

.score-item {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  width: 28%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.score-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.score-item h4 {
  color: #255F85;
  font-size: 18px;
  margin-bottom: 10px;
}

.score-item p {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.back-button,
.explanation-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.back-button {
  background-color: #255F85;
  color: white;
}

.back-button:hover {
  background-color: #1e4c6a;
}

.explanation-button {
  background-color: #FFC857;
  color: #333;
}

.explanation-button:hover {
  background-color: #e6b54e;
}