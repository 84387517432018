.explanations {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.logo {
  display: block;
  margin: 0 auto 20px;
  max-width: 100px; /* Smaller logo */
}

.test-name {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.question-explanation {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.question-header h3 {
  color: #255F85;
  margin: 0;
}

.question-type {
  font-size: 14px;
  color: #666;
  font-style: italic;
}

.question-text {
  font-weight: bold;
  margin-bottom: 15px;
}

.options {
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
}

.option {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: default;
  transition: all 0.3s ease;
}

.option-letter {
  min-width: 30px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFC857;
  color: #000000;
  border-radius: 50%;
  margin-right: 10px;
  font-weight: bold;
}

.option-text {
  flex-grow: 1;
  color: #000000;
  display: flex;
  align-items: center;
  min-height: 30px; /* Match the height of the option-letter */
}

.option.correct {
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.option.user-selected {
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.option.highest-point {
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.correct-marker, 
.incorrect-marker,
.option-point {
  font-weight: bold;
  margin-left: 10px;
}

.correct-marker {
  color: #28a745;
}

.incorrect-marker {
  color: #dc3545;
}

.option-point {
  color: #255F85;
}

.explanation {
  background-color: #e9ecef;
  border-radius: 4px;
  padding: 15px;
}

.explanation h4 {
  margin-bottom: 10px;
  color: #495057;
}

.back-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  color: #255F85;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.back-button:hover {
  background-color: #255F85;
  color: #ffffff;
}

.back-button i {
  margin-right: 10px;
}

/* ... (keep other existing styles) ... */

.navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #255F85;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav-button:hover {
  background-color: #1a4d6d;
}

.nav-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.question-count {
  margin: 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.dashboard-button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.back-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  color: #255F85;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.back-button:hover {
  background-color: #255F85;
  color: #ffffff;
}

.back-button i {
  margin-right: 10px;
}

/* ... (keep other existing styles) ... */

@media (max-width: 768px) {
  .explanations {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .logo {
    max-width: 80px;
  }

  .test-name {
    font-size: 24px;
  }

  .question-explanation {
    padding: 15px;
  }

  .option {
    padding: 8px;
  }

  .navigation-container {
    margin-top: 15px;
  }

  .nav-button {
    width: 36px;
    height: 36px;
  }

  .question-count {
    font-size: 14px;
  }

  .dashboard-button-container {
    margin-top: auto;
    padding: 20px 0;
  }

  .back-button {
    width: 100%;
    justify-content: center;
    padding: 12px 20px;
  }

  .question-image {
    max-width: 100%;
    max-height: 300px; /* Add this line */
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    object-fit: contain; /* Add this line */
  }

  .question-image {
    max-height: 200px; /* Add this line for mobile */
  }
}