.cart-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
  padding-bottom: 20px;
}

.cart-header h2 {
  margin: 0;
  color: #333;
  font-size: 1.5em;
  flex-grow: 1;
}

.cart-header h2::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e6e6e6;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cart-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
}

.cart-content {
  display: flex;
  gap: 2rem;
}

.cart-items {
  flex: 2;
}

.cart-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.cart-item-info {
  flex: 1;
}

.category-label {
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
}

.category-label:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.product-name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.product-name {
  font-size: 1.2rem;
  color: #333;
  margin: 0;
  flex: 1;
}

.product-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.product-details {
  color: #666;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.inline-icon {
  display: flex;
  align-items: center;
}

.inline-icon svg {
  margin-right: 8px;
}

.remove-item {
  background: none;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.remove-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #ff4d4d;
}

.cart-summary {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  position: sticky;
  top: 2rem;
}

.voucher-section {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.voucher-section input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  height: 40px; /* Set a fixed height */
}

.voucher-section button,
.applied-voucher button {
  background-color: #FFC857; /* Changed from #4CAF50 to #FFC857 */
  color: #000000; /* Changed to black for better contrast */
  border: none;
  padding: 0 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  height: 40px; /* Match the height of the input */
  display: flex;
  align-items: center;
  justify-content: center;
}

.voucher-section button:hover,
.applied-voucher button:hover {
  background-color: #E6B54E; /* Darker shade of #FFC857 for hover state */
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.applied-voucher {
  background-color: #e6f7ff;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.applied-voucher p {
  margin: 0;
}

.remove-voucher {
  background: none;
  border: none;
  color: #ff4d4d;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease;
  width: 24px;
  height: 24px;
}

.remove-voucher:hover {
  color: #ff1a1a;
}

.remove-voucher svg {
  width: 20px;
  height: 20px;
}

/* Remove these styles if they exist */
.applied-voucher button {
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.applied-voucher button:hover {
  background-color: transparent;
  transform: none;
  box-shadow: none;
}

.cart-total {
  margin-top: 1rem;
  border-top: 1px solid #ddd;
  padding-top: 1rem;
}

.cart-total p {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  color: #666;
}

.cart-total .final-total {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-top: 1rem;
}

.checkout-button {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: #255F85; /* Changed from #007bff to #255F85 */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
  overflow: hidden;
}

.checkout-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: all 0.6s;
}

.checkout-button:hover::before {
  left: 100%;
}

.checkout-button:hover {
  background-color: #1E4A6A; /* Darker shade of #255F85 for hover state */
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.checkout-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
}

.empty-cart-message {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.empty-cart-icon {
  font-size: 5em;
  margin-bottom: 20px;
  color: #ccc;
}

.empty-cart-icon.animated {
  animation: iconBounce 2s ease-in-out infinite;
}

@keyframes iconBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.back-to-dashboard {
  padding: 10px 20px;
  background-color: #255F85;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.back-to-dashboard:hover {
  background-color: #1E4A6A;
}

@media (max-width: 768px) {
  .cart-content {
    flex-direction: column;
  }

  .cart-summary {
    position: static;
  }
}

/* Add these styles at the end of the file */

.loading-spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.checkout-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.checkout-button:disabled:hover {
  background-color: #cccccc;
  transform: none;
  box-shadow: none;
}

/* Add this at the end of the file */

.promo-code-banner {
  background-color: #FFF3CD;
  border: 1px solid #FFEEBA;
  color: #856404;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 0.9rem;
}

.promo-code-banner strong {
  font-weight: bold;
}