.transactions {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.transactions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px; /* Increased from 30px to 40px */
  position: relative;
  padding-bottom: 20px; /* Added padding-bottom */
}

.transactions h2 {
  margin: 0;
  color: #333;
  font-size: 1.5em;
  flex-grow: 1;
}

.transactions h2::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e6e6e6;
  position: absolute;
  bottom: 0; /* Changed from -30px to 0 */
  left: 0;
}

.transactions-loading,
.transactions-error,
.no-transactions {
  text-align: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  color: #666;
}

.transaction-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.transaction-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 20px;
}

.transaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.transaction-id {
  font-weight: bold;
}

.transaction-status {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
  font-weight: bold;
}

.status-pending { background-color: #ffd700; color: #000; }
.status-paid { background-color: #4caf50; color: #fff; }
.status-expired { background-color: #f44336; color: #fff; }

.transaction-body {
  margin-bottom: 10px;
}

.transaction-body p {
  margin: 5px 0;
}

.transaction-products h4 {
  margin-bottom: 5px;
  color: #000000;
}

.transaction-products ul {
  list-style-type: none;
  padding-left: 0;
}

.transaction-products li {
  margin-bottom: 5px;
}

.load-more-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #45a049;
}

.load-more-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #FF8C00; /* Changed to match Add to Cart button */
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-size: 1.2em;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button:hover {
  background-color: #E67300; /* Darker shade for hover state */
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination button:active {
  transform: scale(0.95);
}

.pagination span {
  font-size: 1em;
  color: #333;
  margin: 0 15px;
}

.continue-payment-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  background-color: #FF8C00;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.continue-payment-button:hover {
  background-color: #E67300;
}

.continue-payment-button:active {
  transform: scale(0.98);
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;  
  padding: 0;
}

.product-pill {
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 4px 8px; 
  font-size: 0.9em;
  color: #333;
  list-style-type: none;
  display: inline-block;
  white-space: nowrap;
}

.refresh-button {
  background-color: transparent;
  border: none;
  color: #4A5568;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50%;
  margin-left: 10px;
  z-index: 1;
}

.refresh-button:hover {
  background-color: #EDF2F7;
  color: #2D3748;
}

.refresh-button:active {
  transform: scale(0.95);
}

.refresh-icon {
  width: 20px;
  height: 20px;
}

.load-more-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #45a049;
}

.load-more-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #FF8C00; /* Changed to match Add to Cart button */
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-size: 1.2em;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button:hover {
  background-color: #E67300; /* Darker shade for hover state */
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination button:active {
  transform: scale(0.95);
}

.pagination span {
  font-size: 1em;
  color: #333;
  margin: 0 15px;
}

.continue-payment-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  background-color: #FF8C00;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.continue-payment-button:hover {
  background-color: #E67300;
}

.continue-payment-button:active {
  transform: scale(0.98);
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px; 
  padding: 0;
}

.product-pill {
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 4px 8px;  
  font-size: 0.9em;
  color: #333;
  list-style-type: none;
  display: inline-block;
  white-space: nowrap;
}

.refresh-button {
  background-color: transparent;
  border: none;
  color: #4A5568;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50%;
  margin-left: 10px;
  z-index: 1;
}

.refresh-button:hover {
  background-color: #EDF2F7;
  color: #2D3748;
}

.refresh-button:active {
  transform: scale(0.95);
}

.refresh-icon {
  width: 20px;
  height: 20px;
}

.empty-transactions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
}

.empty-transactions-message {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.empty-transactions-icon {
  font-size: 5em;
  margin-bottom: 20px;
  color: #ccc;
}

.empty-transactions-icon.animated {
  animation: iconBounce 2s ease-in-out infinite;
}

@keyframes iconBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.back-to-dashboard {
  padding: 10px 20px;
  background-color: #255F85;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.back-to-dashboard:hover {
  background-color: #1E4A6A;
}

.transactions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px; /* Increased from 30px to 40px */
  position: relative;
  padding-bottom: 20px; /* Added padding-bottom */
}

.refresh-button {
  background-color: transparent;
  border: none;
  color: #4A5568;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50%;
  margin-left: 10px;
  z-index: 1;
}

.refresh-icon {
  width: 20px;
  height: 20px;
}

.expiry-time {
  font-size: 0.9em;
  color: #ff6b6b;
  margin-top: 5px;
}

.expiry-time.expired {
  color: #e74c3c;
  font-weight: bold;
}